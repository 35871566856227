import * as CSS from "csstype"
import { throttle } from "./throttle"

export { throttle }

/*

DOM Helpers
===========

*/

export const find = (scope: HTMLElement | Document, selector: string) => scope.querySelector(selector) as HTMLElement

export const findAll = (scope: HTMLElement | Document, selector: string) => [].slice.call(scope.querySelectorAll(selector)) as HTMLElement[]

export const query = (selector: string) => find(document, selector)

export const queryAll = (selector: string) => findAll(document, selector)

export const $ = query

export const $$ = queryAll

export const css = (el: HTMLElement, styles: CSS.Properties) => {
  Object.assign(el.style, styles) // @polyfill Object.assign required for all versions of Internet Explorer
  return el
}

export const on = (
  el: HTMLElement,
  types: string | string[],
  callback: (e: Event) => void,
  options: EventListenerOptions = {}
) => {
  types = Array.isArray(types) ? types : [types]
  types.forEach(type => {
    el.addEventListener(type, callback, options)
  })

  return () => off(el, types, callback)
}

export const off = (el: HTMLElement, types: string | string[], callback: (e: Event) => void) => {
  types = Array.isArray(types) ? types : [types]
  types.forEach(type => {
    el.removeEventListener(type, callback)
  })
}

// Delegate event listening to a parent element
// Use a "!" at the start of the selector (eg `!.myclass`) to run your callback when the event does NOT bubble through the selector. This is useful for situations like "Close the Nav/Tooltip/Modal if a user clicks anywhere NOT inside it".
export const delegate = (
  parent: HTMLElement | Window,
  selector: string,
  types: string | string[],
  callback: (e: Event, target?: HTMLElement) => void,
  options: EventListenerOptions = {}
) => {
  let inverse = false
  if (selector.indexOf("!") === 0) {
    selector = selector.slice(1)
    inverse = true
  }

  const _types = Array.isArray(types) ? types : [types]
  const onEvent = (e: Event) => {
    const target = (e.target as HTMLElement).closest(selector) as HTMLElement
    if (inverse) {
      if (!target) callback(e)
    } else {
      if (target) callback(e, target)
    }
  }
  _types.forEach(type => {
    parent.addEventListener(type, onEvent, options)
  })

  return () => {
    _types.forEach(type => {
      parent.removeEventListener(type, onEvent)
    })
  }
}


/*

offset(el: HTMLElement)
=======================

Returns element offsets and dimensions relative to the html element, excluding any transforms (eg `translateX`, `scaleY` etc) have been applied.

This is designed with the same properties as `Element.getBoundingClientRect()` but the use case is subtly different.

Imagine an element `el` that's `100px` wide and has a css transform `scale(0.5)`. `Element.offsetWidth` returns the value `100` while `Element.getBoundingClientRect().width` returns `50`.

See also `Element.scrollWidth`

> If you need to know the actual size of the content, regardless of how much of it is currently visible, you need to use the Element.scrollWidth and Element.scrollHeight properties. These return the width and height of the entire content of an element, even if only part of it is presently visible due to the use of scroll bars.
> -- https://developer.mozilla.org/en-US/docs/Web/API/CSS_Object_Model/Determining_the_dimensions_of_elements


*/

export interface OffsetData {
  top: number
  bottom: number
  left: number
  right: number
  width: number
  height: number
}

export const offset = (el: HTMLElement): OffsetData => {
  let top = 0
  let left = 0
  const width = el.offsetWidth
  const height = el.offsetHeight

  while (el) {
    top += el.offsetTop
    left += el.offsetLeft
    el = el.offsetParent as HTMLElement
  }

  /*
    clientWidth vs offsetWidth
    tl; dr clientWidth is the inner containing area excluding the scrollbar, probably what we want for the root dimensions

    https://developer.mozilla.org/en-US/docs/Web/API/Element/clientWidth
    https://developer.mozilla.org/en-US/docs/Web/API/HTMLElement/offsetWidth
  */
  const bottom = document.documentElement.clientHeight - height
  const right = document.documentElement.clientWidth - width

  return { top, bottom, left, right, width, height }
}
