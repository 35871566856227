export const openWindow = (url: string, title: string, width: number, height: number) => {
  const left_offset = window.screen.width / 2 - (width / 2 + 10)
  const top_offset = window.screen.height / 2 - (height / 2 + 50)
  const features = [
    "width=" + width,
    "height=" + height,
    "status=no",
    "resizable=1",
    "left=" + left_offset,
    "screenX=" + left_offset,
    "top=" + top_offset,
    "screenY=" + top_offset
  ].join(",")

  window.open(url, title, features)
}


// export const openTwitterWindow = (url: string) => openWindow(url, "Twitter", 550, 250)
//       case "facebook":
// shareWindow(url, "Facebook", 555, 588);
// break;
//       case "twitter":
// shareWindow(url, "Twitter", 550, 250);
// break;
//       case "pinterest":
// shareWindow(url, "Pinterest", 750, 520);
// break;
//       case "linkedin":
// shareWindow(url, "LinkeIn", 750, 749);
// break;