import { $, $$, offset, find, findAll, css, throttle } from "../../../utils/luna"
import { suffixSlash } from "../../../../lib/slash"
import { createSkeleton } from "../../molecules/Preview/Preview.client"
import { formatDateRelative } from "../../../utils/formatDate"

/*

For a great infinite scroll experience we set the height of the document (with JS) so we have a natural scroll, and dynamically fetch data, and toggle visibility of groups of website preivews, on scroll.

- Read groups_data from a data attribute in the DOM. This contains just the height and offset factors for a group of 24 (at the time of writing, see WEBSITES_PER_PAGE) website previews which we use to sync document scroll position with timeline ticker position.
- Using groups_data we know how many groups of website previews there are going to be and we immediately write empty divs onto the page for each one.
- We set an accurate height on each of these group elements using create_group_height_measurer
- (We are not currently taking into account the height of the last item, which will vary based on the number of website previews it contains)
- On scroll we determine intersecting elements and fetch html from their respective /page/x/ URLs.
- On scroll we write or delete innerHTML based on similar intersection data. This means we have very few DOM elements in the document at a given time.

*/

interface GroupDataset {
  ticker_offset: number
  ticker_height: number
}

interface GroupData extends GroupDataset {
  html_str: string
  has_inner_html: boolean
  el: HTMLElement
  fetched: Boolean
  intersecting: Boolean
  top: number
  left: number
  height: number
  url: string
  // index: boolean @todo add page index eg /page/(index + 1)/ so we can
}

export const WebsiteIndex = (async () => {
  const $groups = $("[data-groups]")
  const $ticker = $("[data-ticker]")

  if (!$groups) return

  const html = document.documentElement
  const rAF = requestAnimationFrame

  // State data
  let window_height = html.clientHeight
  let current_url = suffixSlash(window.location.pathname)

  const last_group_length = parseInt($groups.dataset.last_group_length, 10)
  const WEBSITES_PER_PAGE = JSON.parse($groups.dataset.groups).websites_per_page
  const groups_data = JSON.parse($groups.dataset.groups_data) as [string, string][]
  const slug = $groups.dataset.slug as string
  const $page_group = find($groups, "[data-group]")
  const $ticker_links_container = $("[data-ticker-links]")
  const $pager = $("[data-pager]")
  const page_group_index = parseInt($page_group.dataset.page_group_index, 10)
  const should_infinite_scroll = page_group_index === 0

  // Create an element that exactly matches the height of a group of website previews. We'll use this to predict how much space to make on the page for groups that we later load in via fetch.
  const $group_height_measurer = create_group_height_measurer()

  if (should_infinite_scroll) {
    $pager.style.display = "none"

    // @todo clean this code up, removing the need to handle the path where we load *before* the current group since we no longer infinite=scroll anything but the 0 indexed term landing page.

    // Create markup for empty groups, preserving space in the DOM
    // We populate the last group with placeholder skeletons so it's height is accurate based on the number of website previews in it.
    const prepend_groups = []
    const append_groups = []
    groups_data.forEach(([ticker_height, ticker_offset], i, arr) => {
      if (i === page_group_index) return
      const styles = "" // i !== arr.length - 1 ? `style="height: var(--placeholder-height)"` : ""
      const skeletons = "" // i === arr.length - 1 ? createPreviewSkeletonsHTML((new Array(last_group_length)).fill("#fff")) : ""
      const markup = `<div
      class="WebsiteIndex__group"
      ${styles}
      data-group
      data-page_group_index="${i}"
      data-ticker_offset=${ticker_offset}
      data-ticker_height=${ticker_height}
    ><div class="WebsiteIndex__grid">${skeletons}</div></div>`
      if (i < page_group_index) {
        prepend_groups.push(markup)
      } else {
        append_groups.push(markup)
      }
    })

    $groups.insertAdjacentHTML("afterbegin", prepend_groups.join("\n"))
    $groups.insertAdjacentHTML("beforeend", append_groups.join("\n"))
  }
  // @todo update scroll position after inserting markup

  // Track offset (top/left/height) and loaded status or each group.
  let groups: GroupData[] = findAll($groups, "[data-group]")
    .map((el, i) => {
      return {
        url: slug + (i === 0 ? "" : `page/${i + 1}/`), // Must include trailing slash
        el,
        html_str: el.innerHTML,
        has_inner_html: !!(el.innerHTML.trim()),
        fetched: i === page_group_index,
        intersecting: i === page_group_index,
        ...offset(el),
        ticker_height: parseFloat(el.dataset.ticker_height),
        ticker_offset: parseFloat(el.dataset.ticker_offset),
      }
    })
    .reverse()


  function applyRelativeDates () {
    // console.log("applyRelativeDates")
    const newestgroup = groups[groups.length - 1]
    if (newestgroup.has_inner_html) {
      findAll(newestgroup.el, "time").forEach(el => { el.innerText = formatDateRelative(el.getAttribute("datetime")) })
      newestgroup.html_str = newestgroup.el.innerHTML
    } else {
      const parser = new DOMParser()
      const html = parser.parseFromString(newestgroup.html_str, "text/html")
      findAll(html, "time").forEach(el => { el.innerText = formatDateRelative(el.getAttribute("datetime")) })
      newestgroup.html_str = find(html, "body").innerHTML
      newestgroup.el.innerHTML = newestgroup.html_str
    }
  }

  applyRelativeDates()
  let relativeDateInterval = setInterval(applyRelativeDates, (1000 * 30)) // Every 30s

  // Listeners

  const throttledFetchIntersection = throttle(fetchIntersecting, 250)
  window.addEventListener("resize", onResize, { passive: true })
  window.addEventListener("scroll", () => {
    updateTickerOffset()
    if (should_infinite_scroll) throttledFetchIntersection()
  }, { passive: true })

  // if (scrollY > 0)
  updateTickerOffset() // Needed if a page refresh or navigation event remembers a user's scroll position

  // Scroll to chunk on page on Ticker link click

  $ticker_links_container.addEventListener("click", (e: Event) => {
    const anchor = (e.target as HTMLElement).closest("a")
    if (anchor) {
      const { index } = anchor.dataset
      const target = $(`[data-page_group_index="${index}"]`)
      if (target) {
        e.preventDefault()
        window.scrollTo({
          top: target.getBoundingClientRect().y + scrollY - (window_height / 2) + 1, // "+ 1" on the end to fix rounding where we don't quite reach an offset sufficient to trigger the matching chunk's historystate event.
          behavior: "smooth"
        })
      }
      // Implied else is treat as a normal link if target not found
    }
  })

  // Methods

  function updateTickerOffset () {
    let vertical_middle = scrollY + (window_height / 2)

    // Find the chunk that lines up with vertical middle of the screen (and therefore timeline ticker)
    // @note groups are already in reverse DOM order to make this search fast
    const chunk = groups.find((data) => data.top < vertical_middle) || groups[groups.length - 1]
    const scroll_factor = (vertical_middle - chunk.top) / chunk.height

    if (should_infinite_scroll) {
      if (history.pushState) {
        if (chunk.url != current_url) {
          current_url = chunk.url
          history.pushState({}, `Title ...`, current_url)
          // @ts-ignore
          if (fathom) fathom.trackPageview()
        }
      }
    }

    rAF(() => {
      $ticker.style.transform = `translate3d(0, ${scroll_factor * chunk.ticker_height * -100 - chunk.ticker_offset * 100}%, 0)`
    })
  }


  function onResize () {
    html.style.setProperty("--placeholder-height", $group_height_measurer.scrollHeight + "px")
    window_height = html.clientHeight
    groups = groups.map(data => ({ ...data, ...offset(data.el) })) // Measure offset positions
    updateTickerOffset()
  }


  function fetchIntersecting () {
    const threshold = -1000
    const is_intersecting = (data: GroupData) => data.top < scrollY + window_height - threshold && data.top + data.height > scrollY + threshold

    // Find viewport intersecting groups, fetch html if necessary, and display html if intersecting
    groups
      .filter(is_intersecting)
      .forEach(async (data, i) => {
        if (!data.fetched) {
          data.fetched = true
          const response = await fetch(data.url)
          const html_str = await response.text()
          const parser = new DOMParser()
          const html = parser.parseFromString(html_str, "text/html")
          data.html_str = find(html, "[data-group]").innerHTML
          if (!data.has_inner_html) {
            data.has_inner_html = true
            rAF(() => {
              data.el.innerHTML = data.html_str
            })
          }
        }

        if (!data.has_inner_html) {
          data.has_inner_html = true
          rAF(() => {
            data.el.innerHTML = data.html_str
          })
        }
      })

    // Remove HTML from non-intersecting groups
    groups
      .filter(data => !is_intersecting(data))
      .forEach((data, i, arr) => {
        if (data.has_inner_html) {
          data.has_inner_html = false
          rAF(() => {
            data.el.innerHTML = ""
          })
        }
      })
  }


  function create_group_height_measurer () {
    const el = document.createElement("div")
    css(el, { overflow: "hidden", height: 0 })
    el.setAttribute("aria-hidden", "true")
    el.innerHTML = `<div class="WebsiteIndex__group" style="height: auto !important;"><div class="WebsiteIndex__grid">${createPreviewSkeletonsHTML(
      new Array(WEBSITES_PER_PAGE).fill("")
    )}</div></div>` // @note height: auto prevents this element's height from being impacted by --placeholder-height
    $groups.prepend(el)
    void el.scrollHeight // Unnecessary checking height to maybe fix a rare Firefox bug that (I think) is caused by a zero height
    const h = el.scrollHeight // ; console.log(h)
    html.style.setProperty("--placeholder-height", h + "px")
    return el
  }


  function createPreviewSkeletonsHTML (colors: string[]) {
    return colors.map(createSkeleton).join("\n")
  }
})
