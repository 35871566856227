export const prefixSlash = (str: string): string => {
  if (str.startsWith("/")) return str
  return "/" + str
}


export const suffixSlash = (str: string): string => {
  if (str.endsWith("/")) return str
  return str + "/"
}


export const unSlashPrefix = (str: string): string => {
  if (str.startsWith("/")) return str.slice(1)
}