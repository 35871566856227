export const monthNamesAbbr = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
]

// 31.03.2023
export const formatDateShortUTC = (d: string | Date) => {
  if (typeof d === "string") d = new Date(d)

  return [
    d.getUTCDate().toString().padStart(2, "0"),
    (d.getUTCMonth() + 1).toString().padStart(2, "0"),
    d.getUTCFullYear()
  ].join(".")
}

// 9 Sep 2019
export const formatDatePrettyUTC = (d: string | Date) => {
  if (typeof d === "string") d = new Date(d)
  return `${d.getUTCDate()} ${monthNamesAbbr[d.getUTCMonth()]} ${d.getUTCFullYear()}`
}

export const formatDatePretty = (d: string | Date) => {
  if (typeof d === "string") d = new Date(d)

  // 9 Sep 2019
  return `${d.getDate()} ${monthNamesAbbr[d.getMonth()]} ${d.getFullYear()}`
}

// When using in the client, str should conform to the ISO 8601 format (YYYY-MM-DDTHH:mm:ss.sssZ) and have UTC offset representation specified as "Z" (for UTC with no offset) or as either "+" or "-" followed by a time expression HH:mm
export const formatDateRelative = (str: string | Date, utc: boolean = false) => {
  const date = new Date(str)
  const now = new Date()
  const secondsPast = (now.getTime() - date.getTime()) / 1000

  const daySeconds = 86400
  const hourSeconds = 3600
  const minuteSeconds = 60

  const formatter = utc ? formatDatePrettyUTC : formatDatePretty

  // Future
  if (secondsPast < 0) return formatter(date)

  // 10 Days
  if (secondsPast / daySeconds > 10) return formatter(date)

  // Between 10-2 days: n days ago
  if (secondsPast / daySeconds > 2) {
    const days = (secondsPast / daySeconds).toFixed(0)
    return `${days} day${isSingular(days) ? "" : "s"} ago` //mins + " mins ago"
  }

  // Between 48-1 hours: n hours aga
  if (secondsPast / hourSeconds > 1) {
    const hours = (secondsPast / hourSeconds).toFixed(0)
    return `${hours} hour${isSingular(hours) ? "" : "s"} ago` //mins + " mins ago"
  }

  // Between 60-2 minutes: n hours aga
  if (secondsPast / minuteSeconds > 1) {
    const mins = (secondsPast / minuteSeconds).toFixed(0)
    return `${mins} min${isSingular(mins) ? "" : "s"} ago` //mins + " mins ago"
  }

  return "Now"
}


function isSingular (n: number | string) { return n === 1 || n === "1" }


export const formatDateSafeDir = (d: Date) => {
  // return `${d.getFullYear()}-${d.getDate()} ${monthNamesAbbr[d.getMonth()]} `
  return d.getUTCFullYear() + "-" +
    (d.getUTCMonth() + 1).toString().padStart(2, "0") + "-" +
    d.getUTCDate().toString().padStart(2, "0") + "-" +
    d.getUTCHours().toString().padStart(2, "0") +
    d.getUTCMinutes().toString().padStart(2, "0") +
    d.getUTCSeconds().toString().padStart(2, "0")
}