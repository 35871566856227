import { $, find } from "../../../utils/luna"

export const WebsiteView = () => {
  const $pager = $(".WebsiteView__pager")
  if (!$pager) return

  const $next = find($pager, "a[data-next]")
  const $prev = find($pager, "a[data-prev]")
  const RIGHT_ARROW = "ArrowRight"
  const LEFT_ARROW = "ArrowLeft"

  window.addEventListener("keydown", (e: KeyboardEvent) => {
    if (e.key === RIGHT_ARROW && $next) { $next.click() }
    if (e.key === LEFT_ARROW && $prev) { $prev.click() }
  })
}