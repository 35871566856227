import { $, on, delegate } from "../../../utils/luna"
import { openWindow } from "../../../utils/openWindow"

export const Nav = () => {
  let isOpen = false

  const $toggle = $(".Nav__toggle")
  const $menu = $(".Nav__bd")
  const $submit = $("[data-twitter-submit]") as HTMLAnchorElement

  const toggleMenu = () => {
    isOpen = !isOpen
    $toggle.classList.toggle("is-open", isOpen)
    $menu.classList.toggle("is-open", isOpen)
  }

  const openTwitterSubmitWindow = (e: Event) => {
    e.preventDefault()
    openWindow($submit.href, "Submit", 550, 327)
  }

  on($toggle, "click", toggleMenu)
  on($submit, "click", openTwitterSubmitWindow)

  delegate(window, "!.Nav", "click", () => {
    if (isOpen) toggleMenu()
  })
}