import { Layout } from "./ui/layouts/Layout/Layout.client"
import { WebsiteIndex } from "./ui/views/WebsiteIndex/WebsiteIndex.client"
import { WebsiteView } from "./ui/views/WebsiteView/WebsiteView.client"


!(async () => {
  Layout()
  WebsiteIndex()
  WebsiteView()
})()
